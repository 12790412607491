import SearchForm from '../components/SearchForm';
import SearchFormV2 from '../components/SearchFormV2';
import './Homepage.scss';

export default function Homepage() {
    return (

        <div className="Homepage main" id="main">
            <div className="hp-logo">
                <img className="hp-logo-image" src="/demo/logo_composante_recherche_LISA_corse.png" alt="logotype de l'université de Corse et du CNRS" />
                <img className="hp-logo-image" src="/demo/logo_M3C.png" alt="logotype de la M3C - Médiathèque culturelle de la Corse et des Corses" />
            </div>
            <h1>U DIZZIUNARIU CORSU</h1>
            <SearchFormV2 
                autoFocus={true} 
            />
            <div id="result">
                <div id="title-lemme">
                    <h2 id="lemme"></h2>
                    <i id="classNamee-grammaticale"></i>
                </div>
                <div id="definition">
                </div>
            </div>
        </div>
    )
}