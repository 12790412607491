import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/layouts/Header";
import './ResultPage.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import { LemmeData } from "../api/api";
import { capitalizeFirstLetter } from "../utils/format";
import { BarLoader } from "react-spinners";

export default function ResultPage() {

    const { lemme, option } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any | null>(null);
    const searchLemmeCap = lemme ? capitalizeFirstLetter(lemme) : "";
    const navigateTo = useNavigate();


    useEffect(() => {
        fetch(`https://dicofac.cir.nu/ricercaAPI.php?lemme=${lemme}&partie=${option}`)
            .then((response) => response.json())
            .then(data => setData(data))
            .catch(error => {
                console.error('Error fetching data: ', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [lemme, option]);

    function handleOnClickSignalError(lemmeData: LemmeData) {
        const url = `https://dicofac.cir.nu/bug.php?lemme=${lemmeData.lemme}`;

        fetch(url)
            .then(result => result.json())
        alert(`votre signalement a bien été enregistré pour le mot ${lemmeData.lemme} (${lemmeData.classeGrammaticale}), merci pour votre contribution.`)
    }

    function handleOnClickAskForLemme(askedLemme: string | undefined) {
        const url = `https://dicofac.cir.nu/bug.php?demande=${askedLemme}`;

        fetch(url)
            .then(result => result.json())
        alert(`votre demande pour le mot ${askedLemme} a bien été enregistré, merci pour votre contribution.`)
    }

    const lemmes = data && data.length !== 0 ? data.map((lemmeData: LemmeData, index: number) => {

        let voir = lemmeData.voir ?? [];
        let isVoirDefined = voir.length > 0 ?? false;
        let definitions = lemmeData.def ?? [];
        let citations = lemmeData.citat ?? [];
        let proverbes = lemmeData.prov ?? [];
        let dictons = lemmeData.dict ?? [];
        let expressions = lemmeData.exp ?? [];
        let ditti = [...proverbes, ...dictons, ...expressions];
        let lemmeCap = capitalizeFirstLetter(lemmeData.lemme);
        
        return (
            <div className="rp--lemme" key={index}>
                <h2 className="rp--lemme-title" id={lemmeData.lemme}>
                    {lemmeCap}{lemmeData.complement}
                    <i className="rp--gram-class"> {lemmeData.classeGrammaticale}</i>
                    {/* <button className="rp--signal-error" onClick={() => handleOnClickSignalError(lemmeData)}>Erreur ?</button> */}
                </h2>
                <Tabs>
                    <TabList>
                        {/* {voir.length > 0 &&
                            <Tab href="#">Voir</Tab>
                        } */}
                        {definitions.length > 0 &&
                            <Tab href="#">Definizioni</Tab>
                        }
                        {ditti.length > 0 &&
                            <Tab href="#">Detti</Tab>
                        }
                        {citations.length > 0 &&
                            <Tab href="#">Citazioni</Tab>
                        }
                    </TabList>


                    {/* {voir.length > 0 &&
                        <TabPanel>
                            <div className="rp-tabpanel voir">
                                <ol>
                                    {voir.map((voir, index) => {
                                        return (
                                            <li key={index}>
                                                {index + 1}. <Link to={`/${voir}/tout`}>
                                                    {voir}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ol>
                            </div>
                        </TabPanel>
                    } */}
                    {definitions &&
                        <TabPanel>
                            <div className="rp-tabpanel def">
                                <ol>
                                    {isVoirDefined &&
                                        <li key={1}>
                                            {"1. Voir "}
                                            {voir.map<React.ReactNode>((voirElement, index) => {
                                                return (
                                                    <Link to={`/${voir[index]}/tout`}>{voirElement}</Link>
                                                )
                                            }
                                            ).reduce((prev, curr) => [prev, ", ", curr])}.

                                        </li>
                                    }
                                    {definitions.map((definition, index) => {
                                        return (
                                            <li
                                                key={isVoirDefined ? index + 1 : index}
                                            >
                                                {isVoirDefined ? index + 2 : index + 1}. {definition}
                                            </li>
                                        )
                                    })
                                    }
                                </ol>
                            </div>
                        </TabPanel>
                    }
                    {ditti.length > 0 &&
                        <TabPanel>
                            <div className="rp-tabpanel dict">
                                <ol>
                                    {ditti.map((dittu, index) => {
                                        return (
                                            <li key={index++}>{++index}. {dittu}</li>
                                        )
                                    })}
                                </ol>
                            </div>
                        </TabPanel>
                    }
                    {citations.length > 0 &&
                        <TabPanel>
                            <div className="rp-tabpanel citat">
                                <ol>
                                    {
                                        citations.map((citation, index) => {
                                            return (
                                                <li key={index}>{index + 1}. {citation}</li>
                                            )
                                        })
                                    }
                                </ol>
                            </div>
                        </TabPanel>
                    }
                </Tabs >
            </div >
        );
    }) : <>
        <h3 className="rp--word-not-found">parolla "{searchLemmeCap}" scunnisciuta, <span className={"rp--ask-lemme-link"} onClick={() => handleOnClickAskForLemme(searchLemmeCap)}>Dumandà la</span></h3>
        <div className={"btn-return"}><button className="btn" onClick={() => navigateTo('/')}>Casa</button></div>
    </>;




    return (
        <>
            <Header />
            <div className="ResultPage">
                <>
                    <p className="rp--breadcumb"><Link to="/"><i className="material-icons">home</i>{" > Dizziunariu > Definizioni"}</Link></p>
                    <h1>DIZZIUNARIU CORSU : {searchLemmeCap}</h1>
                    {loading ?
                        <div className="rp--loader">
                            <BarLoader
                                color={"#000"}
                                loading={true}
                                // cssOverride={override}
                                width={150}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div> :
                        lemmes
                    }
                </>
            </div>

        </>
    )
}