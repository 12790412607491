import { SetStateAction, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonIcon from '../inputs/ButtonIcon';
import SearchForm from '../SearchForm';
import SearchFormV2 from '../SearchFormV2';
import './Header.scss';

export default function Header() {
    
    return (
        <div className="Header">
            <div className="header--title">
                <Link to="/" >U DIZZIUNARIU CORSU</Link>
            </div>
            <div className="header--search-bar">
                <SearchFormV2 
                    className='sf--header' 
                    autoFocus={false}  
                    haveToShrinkEffect={false}
                />
                {/* <form onSubmit={handleSubmitForm} method='get'>
                    
                <input id="champ" name="lemme" type="search" placeholder="Pichjeti una parolla..." required onChange={handleChangeInputValue}  />
                <ButtonIcon style='primary' buttonClassName='btn-validate' icon="search" type="submit" infotip="Circà!" onClick={() => handleSubmitForm} />
                </form> */}
            </div>
        </div>
    )
}