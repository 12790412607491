import { useNavigate } from 'react-router-dom';
import Header from '../components/layouts/Header';
import './NotFoundPage.scss';

export default function NotFoundPage() {

    const navigateTo = useNavigate(); 


    return (
        <>
        <Header />
        <div className="NotFoundPage">
            <h1>404</h1>
            <p>A pagina chì circate ùn esiste micca.</p>
            <button className="p404--button" onClick={() =>navigateTo('/')}>Casa</button>
        </div>
        </>
    )
}