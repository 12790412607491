import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './tests/reportWebVitals';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Homepage from './pages/Homepage';
import NotFoundPage from './pages/NotFoundPage';
import ResultPage from './pages/ResultPage';
import FooterBar from './components/layouts/FooterBar';
import LoginPage from './pages/LoginPage';
import EditLemmePage from './pages/EditLemmePage';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const theme = createTheme({
	palette: {
		primary: {
			main: '#5472a3',
			light: "#7d99d2",
			dark: "#174172",
			contrastText: '#fff',
		},
		secondary: {
			main: '#d36837',
			light: "#ff9863",
			dark: "#9c3a0a",
			contrastText: '#000',
		},
		background: {
			default: '#F2F2F2'
		}
	}
});

root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<div className="page">
				<div className="page-content">
					<BrowserRouter basename='/demo/'>
						{/* <HeaderDev /> */}
						<Routes>
							<Route path='/' element={<Homepage />} />
							<Route path='/connexion/' element={<LoginPage />} />
							<Route path='/:lemme/:option' element={<ResultPage />} />
							<Route path='/admin/edit/:lemme' element={<EditLemmePage />} />

							<Route path='/*' element={<NotFoundPage />} />
						</Routes>
					</BrowserRouter>
				</div>
				<FooterBar />
			</div>
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
