import { Box, Button, IconButton, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import Header from "../components/layouts/Header";
import AddIcon from '@mui/icons-material/ControlPoint';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import './EditLemmePage.scss';

export default function EditLemmePage() {

    const [inputList, setInputList] = useState([{ variation: "" }])


    // handle input change
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { name, value } = event.target;
        console.log(name);
        const list = [...inputList] as any;
        list[index][name] = value;
        setInputList(list);
    };

    // // handle click event of the Remove button
    const handleRemoveClick = (index: number) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { variation: "" }]);
    };



    return (
        <>
            <Header />
            <div className="EditLemmePage">

                <h1>ÉDITION D'UN LEMME</h1>
                <h2>LEMME :</h2>
                <div className="el--main">
                    <TextField label="Lemme" fullWidth variant="filled" />
                    <TextField label="Voir" fullWidth variant="filled" />
                    <TextField label="Classe" fullWidth variant="filled" />
                    {inputList.map((variation, index) => {
                        return (
                            <div
                                className="box"
                                key={`var_${index}`}
                            >
                                <TextField
                                    name="variation"
                                    label="Variation"
                                    fullWidth
                                    variant="filled"
                                    value={variation.variation}
                                    onChange={(event) => handleInputChange(event, index)}
                                />
                                <div className="btn-box">
                                    {
                                        inputList.length !== 1 &&
                                        <IconButton onClick={() => handleRemoveClick(index)}>
                                            <RemoveIcon />
                                        </IconButton>

                                    }
                                    {inputList.length - 1 === index &&
                                        <IconButton onClick={handleAddClick}>
                                            <AddIcon />
                                        </IconButton>
                                    }
                                </div>
                            </div>
                        )
                    })}
                    <TextField label="Définition" fullWidth variant="filled" />
                    <TextField label="Detti" fullWidth variant="filled" />
                    <TextField label="Citation" fullWidth variant="filled" />
                    <TextField label="Auteur" fullWidth variant="filled" />
                    {/* <IconButton><AddIcon /></IconButton> */}
                </div>
                <div className="el--buttons">
                    <Button variant="outlined">Ajouter une autre signification</Button>
                    <Button variant="contained">Enregistrer</Button>
                </div>
                {/* <div style={{ marginTop: 20 }}>Données Variation : {JSON.stringify(inputList)}</div> */}
            </div>

        </>

    )
}