import React from 'react';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab'; import './LoginPage.scss';
import ButtonIcon from '../components/inputs/ButtonIcon';
import { Link } from 'react-router-dom';
import { apiPost } from '../api/api';

export default function LoginPage() {
    const [loginValue, setLoginValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');

    const handleLoginChange = (event: React.SyntheticEvent, newValue: string) => {
        setLoginValue(newValue);
    };
    
    const handlePasswordChange = (event: React.SyntheticEvent, newValue: string) => {
        setPasswordValue(newValue);
    };

    console.log(loginValue);
    console.log(passwordValue);
    const handleSubmitForm = () => {
        
        const url = `https://dicofac.cir.nu/back_control.php`;
        const body = {
            "type" : "auth",
            "login" : loginValue,
            "password": passwordValue
        }
        apiPost(url, body);
    }

    return (
        <div className='LoginPage'>
            <div className='header'>
                <div className="lp-logo-content">
                    <Link to={"/"}>
                        <img id="lp-logo" src="/demo/logo-provisoire.png" alt="logotype de l'université de Corse et du CNRS" />
                    </Link>
                </div>
                <h1>U DIZZIUNARIU CORSU</h1>
            </div>

            <div className='lp-box'>
                <h2>BONGHJORNU</h2>
                <div className='lp-box-form'>
                    <input type={"text"} placeholder={"Email"} className={"lp-field"} title={"Renseigner votre adresse email"} onChange={(e) => handleLoginChange(e, e.target.value)}></input>
                    <input type={"password"} placeholder={"Mot de passe "} className={"lp-field"} title={"Renseigner votre mot de passe"} onChange={(e) => handlePasswordChange(e, e.target.value)}></input>
                    <ButtonIcon style='primary' buttonClassName='btn-connexion' type="submit" infotip="Connexion" onClick={handleSubmitForm} buttonText={"CONNEXION"} />

                </div>
            </div>

        </div>
    )
}