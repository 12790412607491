import React from 'react';
import './ButtonIcon.scss';

interface Props {
    icon?: string,
    buttonClassName?: string,
    onClick?: () => void,
    type?: 'submit' | 'button',
    style?: 'primary' | 'secondary',
    infotip?: string,
    buttonText?: string,
}

export default function ButtonIcon({
    icon = "",
    buttonClassName,
    onClick,
    type = 'button',
    style = 'primary',
    infotip,
    buttonText
}: Props) {

    return (
        <button
            type={type}
            className={`ButtonIcon ${style} ${buttonClassName ?? ''}`}
            onClick={onClick}
            title={infotip}
        >
            {icon &&
                <i className="material-icons">{icon}</i>
            }
            {buttonText}
        </button>
    )
}