
import './FooterBar.scss';


export default function FooterBar() {
    return (
        <footer className="FooterBar">
            <nav>
                <ul><a href='#'>Mentions légales</a></ul>
                <ul><a href='https://www.universita.corsica/fr/' target={"_blank"}>UniversitàdiCorsica2022</a></ul>
                <ul><a href='#'>Abécédaire</a></ul>
                <ul><a href='#'>Partenaires</a></ul>
            </nav>
        </footer>
    )
}