import './SearchFormV2.scss';
import ButtonIcon from './inputs/ButtonIcon';
import { SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AutocompleteDatalist from './inputs/AutocompleteDatalist';
import { SearchFormOptionPart } from '../api/api';
import SearchOptionDropDownMenu from './inputs/SearchOptionDropDownMenu';
import { Autocomplete, debounce, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { Box } from '@mui/system';


interface Props {
    className?: string,
    autoFocus?: boolean,
    haveToShrinkEffect?: boolean
}

export default function SearchFormV2({ className, autoFocus = false, haveToShrinkEffect = true }: Props) {
    const [searchFormLemme, setSearchFormLemme] = useState<string>("");
    const [searchFormOption, setSearchFormOption] = useState<SearchFormOptionPart>("tout");
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOptionPurposed, setIsOptionPurposed] = useState(true);

    // Lettre minimum pour lancer l'autocomplétion
    const minLetterToSearch = 2;
    const isLemmePurposed = searchFormLemme.length > minLetterToSearch ?? false;
    useEffect(() => {
        if (isLemmePurposed) {
            setIsLoading(true);
            fetch(`https://dicofac.cir.nu/autocomplete.php?lemme=${searchFormLemme}&partie=${searchFormOption}`)
                .then((response) => response.json())
                .then((json) => setOptions(json))
                .then(() => setIsLoading(false));
        } else {
            setIsOptionPurposed(false);
        }
    }, [searchFormLemme, searchFormOption]);

    const navigate = useNavigate();
    const handleSubmitForm = (event?: { preventDefault: () => void; }) => {
        event?.preventDefault();
        const searchFormLemmeCleaned = searchFormLemme.split(",")[0];
        navigate(`/${searchFormLemmeCleaned}/${searchFormOption}`);
    }

    const handleOnClicksSuggestionOption = (lemmeToGo: string) => {
        handleSubmitForm();
        setSearchFormLemme('');
        navigate(`/${lemmeToGo}/tout`);
    }

    const handleOptionChange = (event: SelectChangeEvent) => {
        setSearchFormOption(event.target.value as SearchFormOptionPart);
    }

    return (
        <div id="SearchForm" className={className ?? ""}>
            <form
                id="ricerca"
                method='get'
                onSubmit={handleSubmitForm}
            >
                {/* <input id="champ" name="lemme" type="text" placeholder="Pichjeti una parolla..." autocomplete="off" spellcheck="false" required onKeyDown='nettoieInput()' onClick='activeNettoyage()' /> */}
                <div
                    className={`sf--input-option-bg ${isOptionPurposed ? "with-purpose" : ""}`}
                >
                    <Autocomplete
                        filterOptions={(x) => x}
                        options={options}
                        autoComplete
                        // includeInputInList
                        value={searchFormLemme}
                        noOptionsText="parolla scunnisciuta"
                        id="combo-box-lemme"
                        sx={{ minWidth: 200 }}
                        // clearOnEscape
                        // selectOnFocus
                        freeSolo
                        loading={isLoading}
                        loadingText={"Aspetta..."}
                        onInputChange={(event, newInputValue) => setSearchFormLemme(newInputValue)}
                        onChange={(event, newValue) => newValue ? handleOnClicksSuggestionOption(newValue) : null}
                        open={isOptionPurposed}
                        onOpen={() => setIsOptionPurposed(isLemmePurposed)}
                        onClose={() => setIsOptionPurposed(false)}

                        // autoHighlight={true}
                        // autoSelect={true}
                        isOptionEqualToValue={(option, value) => option === value}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label={haveToShrinkEffect ? "Pichjeti una parolla..." : searchFormLemme ? " " : "Pichjeti una parolla..."}
                                sx={{ "& fieldset": { border: 'none' } }}
                                autoFocus
                                InputLabelProps={{ shrink: haveToShrinkEffect && searchFormLemme ? true : false }}
                                onKeyDown={(e) => e.key === "Enter" ? handleSubmitForm(e) : null}
                            />
                        }
                        renderOption={(props, optionResult) => {
                            return (<Box
                                component={"li"}
                                {...props}
                                key={optionResult}
                                onClick={() => handleOnClicksSuggestionOption(optionResult)}
                            >
                                {optionResult}
                            </Box>);
                        }}
                    />
                    <Select
                        sx={{
                            backgroundColor: 'background.default',
                            "& fieldset": { border: 'none' }
                            // list: {
                            //     boxShadow: "none"
                            // }
                        }}
                        labelId="search-form-option-label"
                        id="search-form-option"
                        value={searchFormOption}
                        onChange={handleOptionChange}
                        className={"option-form"}
                    >
                        <MenuItem value={"tout"}>Sana</MenuItem>
                        <MenuItem value={"debut"}>Principiu</MenuItem>
                        <MenuItem value={"fin"}>Fini</MenuItem>
                        <MenuItem value={"milieu"}>Pezzu</MenuItem>
                    </Select>
                </div>
                <ButtonIcon style='primary' buttonClassName='btn-validate' icon="search" type="submit" infotip="Circà!" onClick={() => handleSubmitForm} />

            </form>
        </div>
    )
};