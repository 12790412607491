



export type LemmeData = {
    lemme: string,
    classeGrammaticale: string,
    complement?: string,
} & ({
    voir: string[],
    def: undefined,
    prov: undefined,
    dict: undefined,
    citat: undefined,
    exp: undefined,
    inter: undefined,
    seren: undefined,
    chans: undefined,
} | {
    voir: undefined,
    def: string[],
    prov: string[],
    dict: string[],
    citat: string[],
    exp: string[],
    inter: string[],
    seren: string[],
    chans: string[],
})


// export type LemmeData = {
//     lemme: string,
//     classeGrammaticale: string,
//     complement?: string,
// } & ({
//     voir: string,
//     def: null,
//     ditti: null,
//     citat: null,
// } | {
//     voir: null,
//     def: string[],
//     ditti: Ditti,
//     citat: string[],
// })

export type Ditti = {
    prov?: string[],
    dict?: string[],
    exp?: string[],
    inter?: string[],
    seren?: string[],
    chans?: string[],
}





export type SearchFormOptionPart = "tout" | "debut" | "fin" | "milieu";


export function apiPost(requestInfo: RequestInfo, body: any) {

    const requestInit: RequestInit = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        }
    }

    return fetch(requestInfo, requestInit)
}

